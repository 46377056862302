export const menuList = [
  {
    path: "/commons/password/same",
    name: "passwordsame",
    component: "commons/password/same",
    meta: {
      title: "密码管理"
    },
  },
  {
    path: "/commons/password/index",
    name: "passwordindex",
    component: "commons/password/index",
    meta: {
      title: "密码管理"
    },
  },
  {
    path: "/commons/user/index",
    name: "userindex",
    component: "commons/user/index",
    meta: {
      title: "个人中心",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/edit",
    name: "useredit",
    component: "commons/user/edit",
    meta: {
      title: "个人中心修改"
    },
  },
  {
    path: "/commons/user/lvl-up",
    name: "lvl-up",
    component: "commons/user/lvl-up",
    meta: {
      title: "权益升级",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/order-rights",
    name: "order-rights",
    component: "commons/user/order-rights",
    meta: {
      title: "发单购买",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/order-rights-pay",
    name: "order-rights-pay",
    component: "commons/user/order-rights-pay",
    meta: {
      title: "询价次数支付",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/member-buy",
    name: "member-buy",
    component: "commons/user/member-buy",
    meta: {
      title: "会员购买",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/member-pay",
    name: "member-pay",
    component: "commons/user/member-pay",
    meta: {
      title: "会员购买支付",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/pay-success",
    name: "pay-success",
    component: "commons/user/pay-success",
    meta: {
      title: "支付成功",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/bill-list",
    name: "bill-list",
    component: "commons/user/bill-list",
    meta: {
      title: "账单记录",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/grow",
    name: "grow",
    component: "commons/user/grow",
    meta: {
      title: "我的成长值",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/grow-list",
    name: "grow-list",
    component: "commons/user/grow-list",
    meta: {
      title: "我的成长值",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/missions",
    name: "missions",
    component: "commons/user/missions",
    meta: {
      title: "任务列表",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/supplier/businessManagement/index",
    name: "index",
    meta: {
      title: "企业管理",
      roles: ["admin", "super", "some"],
    },
    component: "supplier/businessManagement/index",
  },
  {
    parentPath: '/supplier/businessManagement/index',
    path: "/supplier/businessManagement/userManger",
    name: "userManger",
    meta: {
      title: "用户管理",
      roles: ["admin", "super", "some"],
    },
    component: "supplier/businessManagement/userManger",
  },
  {
    parentPath: '/supplier/businessManagement/index',
    path: "/supplier/businessManagement/roleManger",
    name: "roleManger",
    meta: {
      title: "角色管理",
      roles: ["admin", "super", "some"],
    },
    component: "supplier/businessManagement/roleManger",
  },
  {
    parentPath: '/supplier/businessManagement/index',
    path: "/supplier/businessManagement/userInvitation",
    name: "userInvitation",
    meta: {
      title: "邀请用户",
      roles: ["admin", "super", "some"],
    },
    component: "supplier/businessManagement/userInvitation",
  },
  {
    path: "/supplier/businessManagement/invitationInfo",
    name: "invitationInfo",
    meta: {
      title: "详情",
      roles: ["admin", "super", "some"],
    },
    component: "supplier/businessManagement/invitationInfo",
  },
  {
    path: '/Purchaser/preferentialInquiry/index',
    name: 'preferentialInquiryIndex',
    meta: {
      title: '慧询价',
      roles: ['admin']
    },
    component: 'Purchaser/preferentialInquiry/index',
  },
  {
    path: '/Purchaser/preferentialInquiry/inquiryMore',
    name: 'inquiryMore',
    meta: {
      title: '慧询价-询价采购更多',
      roles: ['admin']
    },
    component: 'Purchaser/preferentialInquiry/inquiryMore',
  },

  {
    path: '/Purchaser/preferentialInquiry/orderMore',
    name: 'orderMore',
    meta: {
      title: '慧询价-招标采购更多',
      roles: ['admin']
    },
    component: 'Purchaser/preferentialInquiry/orderMore',
  },
  {
    path: '/Purchaser/preferentialInquiry/inquiryRankingMore',
    name: 'inquiryRankingMore',
    meta: {
      title: '慧询价-排行榜更多',
      roles: ['admin']
    },
    component: 'Purchaser/preferentialInquiry/inquiryRankingMore',
  },
  {
    path: '/Purchaser/preferentialInquiry/inquiryRankingDetail',
    name: 'inquiryRankingDetail',
    meta: {
      title: '慧询价-排行榜详情',
      roles: ['admin']
    },
    component: 'Purchaser/preferentialInquiry/inquiryRankingDetail',
  },
  {
    path: '/Purchaser/preferentialInquiry/tenderDetails',
    name: 'tenderDetails',
    meta: {
      title: '慧询价-招标详情',
      roles: ['admin']
    },
    component: 'Purchaser/preferentialInquiry/tenderDetails',
  },
  {
    path: '/Purchaser/preferentialInquiry/inquiryDetail',
    name: 'inquiryDetail',
    meta: {
      title: '慧询价-询价详情',
      roles: ['admin'],
    },
    component: 'Purchaser/preferentialInquiry/inquiryDetail',
  },
  {
    path: '/Purchaser/myInquiry/index',
    name: 'myInquiryIndex',
    meta: {
      title: '我的询价',
      roles: ['admin']
    },
    component: 'Purchaser/myInquiry/index',
  },
  {
    path: '/Purchaser/myInquiry/add',
    name: 'myInquiryIndexAdd',
    meta: {
      title: '我的询价-新增',
      roles: ['admin']
    },
    component: 'Purchaser/myInquiry/addInquirySheet',
  },
  {
    path: '/Purchaser/myInquiry/detail',
    name: 'myInquiryIndexDetail',
    meta: {
      title: '我的询价-详情',
      roles: ['admin']
    },
    component: 'Purchaser/myInquiry/addInquirySheet',
  },
  {
    path: '/Purchaser/myInquiry/edit',
    name: 'myInquiryIndexEdit',
    meta: {
      title: '我的询价-编辑',
      roles: ['admin']
    },
    component: 'Purchaser/myInquiry/addInquirySheet',
  },
  {
    path: '/Purchaser/myInquiry/selectingConfirm',
    name: 'myInquiryIndex',
    meta: {
      title: '选标-确认下单',
      roles: ['admin']
    },
    component: 'Purchaser/myInquiry/selectingConfirm',
  },
  {
    path: '/Purchaser/myInquiry/selectingBiddingConfirm',
    name: 'myInquiryIndex',
    meta: {
      title: '选标-确认下单',
      roles: ['admin']
    },
    component: 'Purchaser/myInquiry/selectingBiddingConfirm',
  },
  {
    path: '/Purchaser/myInquiry/allPage',
    name: 'allPage',
    meta: {
      title: '查看全部',
      roles: ['admin']
    },
    component: 'Purchaser/myInquiry/allPage',
  },
  {
    path: '/Purchaser/myInquiryOrder/index',
    name: 'myInquiryOrder',
    meta: {
      title: '我的询价订单',
      roles: ['admin']
    },
    component: 'Purchaser/myInquiryOrder/index',
  },
  {
    path: '/Purchaser/myInquiryOrder/detail',
    name: 'myInquiryOrderDetail',
    meta: {
      title: '我的询价订单-详情',
      roles: ['admin']
    },
    component: 'Purchaser/myInquiryOrder/detail',
  },
  {
    path: "/supplier/myClient/index",
    name: "myClientIndex",
    meta: {
      title: "我的客户",
      roles: ["admin", "super", "some"],
    },
    component: "supplier/myClient/index",
  },
  {
    path: "/supplier/myClient/add",
    name: "myClientAdd",
    meta: {
      title: "我的客户-新增",
      roles: ["admin", "super", "some"],
    },
    component: "supplier/myClient/add",
  },
  {
    path: "/supplier/myClient/edit",
    name: "myClientEdit",
    meta: {
      title: "我的客户-修改",
      roles: ["admin", "super", "some"],
    },
    component: "supplier/myClient/edit",
  },
  {
    path: "/supplier/myClient/detail",
    name: "myClientDetail",
    meta: {
      title: "我的客户-详情",
      roles: ["admin", "super", "some"],
    },
    component: "supplier/myClient/detail",
  },
  {
    path: '/supplier/bankcard/index',
    name: 'bankcardIndex',
    meta: {
      title: '银行卡管理',
      roles: ["admin", "super", "some"]
    },
    component: 'supplier/bankcard/index',
  },
  {
    path: '/supplier/notice/index',
    name: 'noticeIndex',
    meta: {
      title: '消息管理',
      roles: ['admin']
    },
    component: 'supplier/notice/index',
  },
  {
    path: '/supplier/notice/detail',
    name: 'noticeDetailIndex',
    meta: {
      title: '详情',
      roles: ['admin']
    },
    component: 'supplier/notice/detail',
  },
  {
    path: '/supplier/balance/index',
    name: 'balanceIndex',
    meta: {
      title: '账户余额',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/balance/index',
    children: []
  },
  {
    path: '/supplier/mySupplier/index',
    name: 'mySupplier',
    meta: {
      title: '我的供应商',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/mySupplier/index',
  },
  {
    path: '/supplier/wishList/wishList',
    name: 'wishList',
    meta: {
      title: '我的收藏',
      roles: ['admin']
    },
    component: 'supplier/wishList/wishList',
  },
  {
    path: '/supplier/addressManagement/addressList',
    name: 'addressList',
    meta: {
      title: '收件地址',
      roles: ['admin']
    },
    component: 'supplier/addressManagement/addressList',
  },
  {
    path: '/supplier/feedback/feedback',
    name: 'feedback',
    meta: {
      title: '意见反馈',
      roles: ['admin']
    },
    component: 'supplier/feedback/feedback',
  },
  {
    path: '/supplier/enterpriseKanban/index',
    name: 'enterprise',
    meta: {
      title: '企业看板',
      roles: ['admin']
    },
    component: 'supplier/enterpriseKanban/index',
  },
  {
    path: '/supplier/feedback/feedManger',
    name: 'feedManger',
    meta: {
      title: '反馈列表',
      roles: ['admin']
    },
    component: 'supplier/feedback/feedManger',
  },
  {
    path: '/supplier/feedback/feedDetail',
    name: 'feedDetail',
    meta: {
      title: '反馈详情',
      roles: ['admin']
    },
    component: 'supplier/feedback/feedDetail',
  },
  {
    path: '/supplier/helpcenter/helpcenter',
    name: 'helpcenter',
    meta: {
      title: '帮助中心',
      roles: ['admin']
    },
    component: 'supplier/helpcenter/helpcenter',
  },
  {
    path: "/commons/user/new-vip",
    name: "member-buy",
    component: "commons/user/new-vip",
    meta: {
      title: "会员购买",
      roles: ["admin", "super", "some"],
    },
  },
  // {
  //   path: "/commons/user/vip-info",
  //   name: "member-buy",
  //   component: "commons/user/vip-info",
  //   meta: {
  //     title: "会员详情",
  //     roles: ["admin", "super", "some"],
  //   },
  // },
  {
    path: "/commons/sign/sign",
    name: "sign",
    component: "commons/sign/sign",
    meta: {
      title: "签章中心",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/sign/authentication-person",
    name: "authenticationPerson",
    component: "commons/sign/authentication-person",
    meta: {
      title: "签章个人认证",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/sign/authentication-company",
    name: "authenticationCompany",
    component: "commons/sign/authentication-company",
    meta: {
      title: "签章企业认证",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/sign/sure-pay/index",
    name: "authsurepay",
    component: "commons/sign/sure-pay/index",
    meta: {
      title: "签章下单",
      roles: ["admin", "super", "some"],
    },
  },{
    path: "/commons/sign/pay-success/index",
    name: "authsurepay",
    component: "commons/sign/pay-success/index",
    meta: {
      title: "签章下单成功跳转",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/coupons",
    name: "coupons",
    component: "commons/user/coupons",
    meta: {
      title: "我的卡券",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/vip-pay-success",
    name: "vip-pay-success",
    component: "commons/user/vip-pay-success",
    meta: {
      title: "会员-支付成功",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/transaction-detail",
    name: "transaction-detail",
    component: "commons/user/transaction-detail",
    meta: {
      title: "交易明细",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: "/commons/user/vip-records",
    name: "member-buy",
    component: "commons/user/vip-records",
    meta: {
      title: "会员开通记录",
      roles: ["admin", "super", "some"],
    },
  },
  {
    path: '/supplier/membercenter/membercenter',
    name: 'helpcenter',
    meta: {
      title: '会员邀请',
      roles: ['admin']
    },
    component: 'supplier/membercenter/membercenter',
  },
  {
    path: '/supplier/standardCertification/index',
    name: 'standardCertification',
    meta: {
      title: '账户认证',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/standardCertification/index',
  },
  {
    path: '/supplier/standardCertification/detail',
    name: 'standardCertificationdetail',
    meta: {
      title: '账户认证详情',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/standardCertification/detail',
  },
  {
    path: '/supplier/standardCertification/personalCertificationSub',
    name: 'personalCertificationSub',
    meta: {
      title: '认证结果',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/standardCertification/personalCertificationSub',
  },
  {
    path: '/supplier/standardCertification/corporateCertificationSub',
    name: 'corporateCertificationSub',
    meta: {
      title: '认证结果',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/standardCertification/corporateCertificationSub',
  },
  {
    path: '/enterpriseKanban',
    name: 'enterpriseKanban',
    meta: {
      title: '企业看把你',
      roles: ['admin', "super", "some"]
    },
    component: 'enterpriseKanban/index',
  },
  {
    path: '/supplier/mySupplier/supplier_company_detail',
    name: 'mySupplier',
    meta: {
      title: '供应商',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/mySupplier/supplier_company_detail',
  },
  {
    path: '/supplier/quotedPrice/index',
    name: 'quotedPrice',
    meta: {
      title: '报价列表',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/quotedPrice/index',
  },
  {
    path: '/supplier/quotedPrice/quoted_company_detail',
    name: 'quotedDetail',
    meta: {
      title: '报价详情',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/quotedPrice/quoted_company_detail',
  },
  {
    path: '/supplier/bid/index',
    name: 'bid',
    meta: {
      title: '投标列表',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/bid/index',
  },
  {
    path: '/sc-supplier/bid/bid_company_detail',
    name: 'bidDetail',
    meta: {
      title: '投标详情',
      roles: ['admin', "super", "some"]
    },
    component: 'sc-supplier/bid/bid_company_detail',
  },

  {
    path: '/supplier/evaluation/index',
    name: 'evaluation',
    meta: {
      title: '评价管理',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/evaluation/index',
  },
  
  {
    path: '/supplier/myShop/myShop',
    name: 'myShop',
    meta: {
      title: '我的店铺',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/myShop/myShop',
  },
  {
    path: '/supplier/myShop/builtmyShop',
    name: 'builtmyShop',
    meta: {
      title: '创建店铺',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/myShop/builtmyShop',
  },
  {
    path: '/supplier/myShop/builtResult',
    name: 'builtResult',
    meta: {
      title: '创建店铺结果',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/myShop/builtResult',
  },
  {
    path: '/supplier/mySupplier/supplier_person_detail',
    name: 'supplier_person_detail',
    meta: {
      title: '供应商',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/mySupplier/supplier_person_detail',
  },
  {
    path: '/Purchaser/myBidding/index',
    name: 'muBiddong',
    meta: {
      title: '我的招标',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myBidding/index',
  },
  {
    path: '/Purchaser/myBidding/subPage/public',
    name: 'muBiddong-new',
    meta: {
      title: '我的招标-新增',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myBidding/subPage/public',
  },
  {
    path: '/Purchaser/myBidding/subPage/view',
    name: 'muBiddong-view',
    meta: {
      title: '我的招标-详情',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myBidding/subPage/public',
  },
  {
    path: '/Purchaser/myPro/index',
    name: 'myProIndex',
    meta: {
      title: '我的商品订单',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myPro/index',
  }, {
    path: '/Purchaser/myPro/pay',
    name: 'myProPay',
    meta: {
      title: '我的商品订单-订单付款',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myPro/pay',
  }, {
    path: '/Purchaser/myPro/drawback',
    name: 'myProDrawback',
    meta: {
      title: '我的商品订单-退款详情',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myPro/drawback',
  }, {
    path: '/Purchaser/myPro/paySuccess',
    name: 'myProPaySuccess',
    meta: {
      title: '我的商品订单-支付成功',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myPro/paySuccess',
  }, {
    path: '/Purchaser/myPro/carryOver',
    name: 'myProCarryOver',
    meta: {
      title: '我的商品订单-对公转账',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myPro/carryOver',
  }, {
    path: '/Purchaser/myPro/placeOrder',
    name: 'myProPlaceOrder',
    meta: {
      title: '我的商品订单-确认下单',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myPro/placeOrder',
  }, {
    path: '/Purchaser/myPro/details',
    name: 'myProScrabbleDetails',
    meta: {
      title: '我的商品订单-订单详情',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myPro/details',
  }, {
    path: '/Purchaser/myPro/scrabbleDough',
    name: 'myProScrabbleDough',
    meta: {
      title: '我的商品订单-订单详情-拼团',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myPro/scrabbleDough',
  }, {
    path: '/Purchaser/myPro/scrabbleDoughSucc',
    name: 'myProScrabbleDoughSucc',
    meta: {
      title: '我的商品订单-订单详情-拼团成功',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myPro/scrabbleDoughSucc',
  }, {
    path: '/supplier/myPro/index',
    name: 'smyProIndex',
    meta: {
      title: '我的商品订单',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/myPro/index',
  }, {
    path: '/supplier/myPro/details',
    name: 'smyProScrabbleDetails',
    meta: {
      title: '我的商品订单-订单详情',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/myPro/details',
  },
  {
    path: '/supplier/jointPruchasing/index',
    name: 'jointPruchasing',
    meta: {
      title: '我的联采',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/jointPruchasing/index',
  },
  {
    path: '/supplier/jointPruchasing/applyDetail',
    name: 'jointPruchasing_applyDetail',
    meta: {
      title: '申请详情',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/jointPruchasing/applyDetail',
  },
  {
    path: '/supplier/jointPruchasing/invitedDetail',
    name: 'jointPruchasing_invitedDetail',
    meta: {
      title: '招募详情',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/jointPruchasing/invitedDetail',
  }, {
    path: '/commons/password/forgetInViews/index',
    name: 'muBiddong-new',
    meta: {
      title: '忘记密码-登录',
      roles: ['admin', "super", "some"]
    },
    component: 'commons/password/forgetInViews/index',
  },
  {
    path: '/supplier/jointPruchasingOrder/index',
    name: 'jointPruchasingOrderIndex',
    meta: {
      title: '我的联采订单',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/jointPruchasingOrder/index',
  }, {
    path: '/supplier/jointPruchasingOrder/details',
    name: 'jointPruchasingOrderDetail',
    meta: {
      title: '我的联采订单-订单详情',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/jointPruchasingOrder/details',
  }, {
    path: '/Purchaser/myBiddingOrder/index',
    name: 'myBiddingOrder',
    meta: {
      title: '我的招标订单',
      roles: ['admin', "super", "some"]
    },
    component: 'Purchaser/myBiddingOrder/index',
  }, {
    path: '/supplier/proManage/index',
    name: 'proManageIndex',
    meta: {
      title: '商品管理',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/proManage/index',
  }, {
    path: '/commons/sure-down-order/index',
    name: 'sureDownOrder',
    meta: {
      title: '确认下单',
      roles: ['admin', "super", "some"]
    },
    component: 'commons/sure-down-order/index',
  }, {
    path: '/commons/sure-pay/index',
    name: 'surePay',
    meta: {
      title: '确认付款',
      roles: ['admin', "super", "some"]
    },
    component: 'commons/sure-pay/index',
  }, {
    path: '/commons/pay-success/index',
    name: 'paySuccess',
    meta: {
      title: '支付成功',
      roles: ['admin', "super", "some"]
    },
    component: 'commons/pay-success/index',
  }, {
    path: '/commons/corporate-transfer/index',
    name: 'paySuccess',
    meta: {
      title: '对公转账',
      roles: ['admin', "super", "some"]
    },
    component: 'commons/corporate-transfer/index',
  }, {
    path: '/commons/order-detail/index',
    name: 'orderDetail',
    meta: {
      title: '订单详情',
      roles: ['admin', "super", "some"]
    },
    component: 'commons/order-detail/index',
  }, {
    path: '/commons/order-detail/refund',
    name: 'refundDetail',
    meta: {
      title: '订单详情',
      roles: ['admin', "super", "some"]
    },
    component: 'commons/order-detail/refund',
  }, {
    path: '/supplier/proManage/details',
    name: 'proManageDetails',
    meta: {
      title: '商品管理-商品详情',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/proManage/details',
  }, {
    path: '/supplier/proManage/proPublish',
    name: 'proManagePublish',
    meta: {
      title: '商品管理-商品发布',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/proManage/proPublish',
  }, {
    path: '/supplier/proManage/proSupplier',
    name: 'proManageSupplier',
    meta: {
      title: '商品管理-商品详情-供应商',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/proManage/proSupplier',
  }, {
    path: '/supplier/proManage/proPublishEdit',
    name: 'proManagePublishEdit',
    meta: {
      title: '商品管理-商品发布-编辑',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/proManage/proPublishEdit',
  }, {
    path: '/supplier/proManage/proScrabbleDough',
    name: 'proManageScrabbleDough',
    meta: {
      title: '商品管理-商品详情-拼团',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/proManage/proScrabbleDough',
  },
  {
    path: '/supplier/myInquiryOrder/index',
    name: 'mySupplierInquiryOrder',
    meta: {
      title: '我的询价订单',
      roles: ['admin']
    },
    component: 'supplier/MyInquiryOrder/index',
  },
  {
    path: '/supplier/myInquiryOrder/detail',
    name: 'mySupplierInquiryOrderDetail',
    meta: {
      title: '订单详情',
      roles: ['admin']
    },
    component: 'supplier/MyInquiryOrder/detail',
  },
  {
    path: "/Purchaser/groundMaterialMap/index",
    name: "groundMaterialMapIndex",
    meta: {
      title: "地材地图",
      roles: ["admin", "super", "some"],
    },
    component: "Purchaser/groundMaterialMap/index",
  },
  {
    path: "/Purchaser/groundMaterialMap/details",
    name: "groundMaterialMapDetails",
    meta: {
      title: "地材地图-详情",
      roles: ["admin", "super", "some"],
    },
    component: "Purchaser/groundMaterialMap/details",
  },
  {
    path: "/Purchaser/findDevices/index",
    name: "findDevicesIndex",
    meta: {
      title: "找设备",
      roles: ["admin", "super", "some"],
    },
    component: "Purchaser/findDevices/index",
  },
  {
    path: "/Purchaser/findDevices/listNeed",
    name: "findDevicesListNeed",
    meta: {
      title: "找设备-需求列表",
      roles: ["admin", "super", "some"],
    },
    component: "Purchaser/findDevices/listNeed",
  },
  {
    path: "/Purchaser/findDevices/listSupply",
    name: "findDevicesListSupply",
    meta: {
      title: "找设备-供应列表",
      roles: ["admin", "super", "some"],
    },
    component: "Purchaser/findDevices/listSupply",
  },
  {
    path: "/Purchaser/findDevices/detailNeed",
    name: "detailNeed",
    meta: {
      title: "找设备-需求详情",
      roles: ["admin", "super", "some"],
    },
    component: "Purchaser/findDevices/detailNeed",
  },
  {
    path: "/Purchaser/findDevices/detailSupply",
    name: "detailSupply",
    meta: {
      title: "找设备-供应详情",
      roles: ["admin", "super", "some"],
    },
    component: "Purchaser/findDevices/detailSupply",
  },
  {
    path: "/refunds/index",
    name: "refundsIndex",
    meta: {
      title: "退货退款",
      roles: ["admin", "super", "some"],
    },
    component: "refunds/index",
  },
  {
    path: "/refunds/detail",
    name: "refundsDetail",
    meta: {
      title: "退货退款-详情",
      roles: ["admin", "super", "some"],
    },
    component: "refunds/detail",
  },
  {
    path: "/findequipment/xindex",
    name: "findequipmentIndex",
    meta: {
      title: "设备需求",
      roles: ["admin", "super", "some"],
    },
    component: "findequipment/index",
  },
  {
    path: "/findequipment/gindex",
    name: "findequipmentIndex",
    meta: {
      title: "设备供应",
      roles: ["admin", "super", "some"],
    },
    component: "findequipment/index",
  },
  {
    parentPath: '/findequipment/xindex',
    path: "/findequipment/pAdd",
    name: "findequipmentPAdd",
    meta: {
      title: "找设备-新增",
      roles: ["admin", "super", "some"],
    },
    component: "findequipment/pAdd",
  },
  {
    parentPath: '/findequipment/gindex',
    path: "/findequipment/gAdd",
    name: "findequipmentGAdd",
    meta: {
      title: "找设备-新增",
      roles: ["admin", "super", "some"],
    },
    component: "findequipment/gAdd",
  },
  {
    path: "/findequipment/pDetail",
    name: "findequipmentpDetail",
    meta: {
      title: "找设备-新增",
      roles: ["admin", "super", "some"],
    },
    component: "findequipment/pAdd",
  },
  {
    path: "/Purchaser/findProject/index",
    name: "findProjectIndex",
    meta: {
      title: "项目分包",
      roles: ["admin", "super", "some"],
    },
    component: "Purchaser/findProject/index",
  },
  {
    parentPath: '/Purchaser/findProject/index',
    path: "/Purchaser/findProject/citytList",
    name: "citytList",
    meta: {
      title: "项目新增",
      roles: ["admin", "super", "some"],
    },
    component: "Purchaser/findProject/citytList",
  },
  {
    path: '/supplier/myBidOrder',
    name: 'mySupplierBidOrder',
    meta: {
      title: '我的投标订单',
      roles: ['admin']
    },
    component: 'supplier/myBidOrder/index',
  },
  {
    path: '/supplier/myBidOrder/detail',
    name: 'mySupplierBidOrder',
    meta: {
      title: '订单详情',
      roles: ['admin']
    },
    component: 'supplier/myBidOrder/detail',
  },
  {
    path: '/supplier/areaMaterial/index',
    name: 'mySupplierBidOrder',
    meta: {
      title: '申请地材供应商',
      roles: ['admin']
    },
    component: 'supplier/areaMaterial/index',
  },
  {
    path: '/supplier/activityAward/activity',
    name: 'activityAward',
    meta: {
      title: '活动有礼',
      roles: ['admin']
    },
    component: 'supplier/activityAward/activity',
  },
  {
    path: '/supplier/activityAward/activityDetail',
    name: 'activityDetail',
    meta: {
      title: '活动详情',
      roles: ['admin']
    },
    component: 'supplier/activityAward/activityDetail',
  },
  {
    path: '/supplier/balancePay/index',
    name: 'balanceIndex',
    meta: {
      title: '账户余额-确认付款',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/balancePay/index',
  },
  {
    path: '/supplier/financial/index',
    name: 'financialIndex',
    meta: {
      title: '结算单',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/financial/index',
  },
  {
    path: '/labor-services/index',
    name: 'laborService',
    meta: {
      title: '劳务用工',
      roles: ['admin', "super", "some"]
    },
    component: 'labor-services/index',
  },
  {
    path: '/financial-service/index',
    name: 'financiaService',
    meta: {
      title: '金融服务',
      roles: ['admin', "super", "some"]
    },
    component: 'financial-service/index',
  },
  {
    parentPath: '/financial-service/index',
    path: '/financial-service/financial-service-detail',
    name: 'financialServiceDetail',
    meta: {
      title: '金融服务详情',
      roles: ['admin', "super", "some"]
    },
    component: 'financial-service/financial-service-detail',
  },
  {
    parentPath: '/financial-service/index',
    path: '/financial-service/financial-service-add',
    name: 'financialServiceAdd',
    meta: {
      title: '金融服务添加',
      roles: ['admin', "super", "some"]
    },
    component: 'financial-service/financial-service-add',
  },
  {
    path: '/supplier/standardSupplier/index',
    name: 'standardSupplier',
    meta: {
      title: '申请认证供应商',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/standardSupplier/index',
  },
  {
    path: '/commons/btbt-pay/index',
    name: 'btbtPay',
    meta: {
      title: '公对公转账跳转页面',
      roles: ['admin', "super", "some"]
    },
    component: 'commons/btbt-pay/index',
  }
  ,
  {
    path: '/supplier/collectionSet/collectionSet',
    name: 'collectionSet',
    meta: {
      title: '收款设置',
      roles: ['admin', "super", "some"]
    },
    component: 'supplier/collectionSet/collectionSet',
  },
  {
    path: '/Purchaser/hybrid-order/index',
    name: 'HybridOrder',
    meta: {
      title: '订单中心',
      roles: ["admin", "super", "some"],
    },
    component: 'Purchaser/hybrid-order/index',
  },
  {
    path: '/supplier/hybrid-order/index',
    name: 'SupplierHybridOrder',
    meta: {
      title: '订单中心',
      roles: ["admin", "super", "some"],
    },
    component: 'supplier/hybrid-order/index',
  },
  {
    path: '/sc-supplier/bid/index',
    name: 'ScSupplierBidIndex',
    meta: {
      title: '招标管理',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-supplier/bid/index',
  },
  {
    path: '/sc-purchaser/my-bidding/index',
    name: 'scpurchaserMyBiddingIndex',
    meta: {
      title: '招标管理',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-purchaser/my-bidding/index',
  },
  {
    path: '/sc-purchaser/my-bidding/win-bidding',
    name: 'scpurchaserMyBiddingwinBidding',
    meta: {
      title: '中标管理',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-purchaser/my-bidding/win-bidding',
  },
  {
    path: '/sc-purchaser/my-bidding/publish-index',
    name: 'scpurchaserMyBiddingPublishindex',
    meta: {
      title: '对外发布',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-purchaser/my-bidding/publish-index',
  },
  {
    path: '/sc-purchaser/my-bidding/subPage/view?type="详情"',
    name: 'scpurchaserMyBiddingView',
    meta: {
      title: '招标详情',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-purchaser/my-bidding/subPage/public',
  },
  {
    path: '/sc-purchaser/my-bidding/subPage/public',
    name: 'scpurchaserMyBiddingPublic',
    meta: {
      title: '招标新增',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-purchaser/my-bidding/subPage/public',
  },
  {
    path: '/sc-purchaser/my-bidding/components/bidding-documents/selectingBiddingConfirm',
    name: 'scpurchaserMyBiddingselecting',
    meta: {
      title: '招标下单',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-purchaser/my-bidding/components/bidding-documents/selectingBiddingConfirm',
  },
  {
    path: '/sc-purchaser/earnest-money/index',
    name: 'EarnestMoney',
    meta: {
      title: '保证金管理',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-purchaser/earnest-money/index',
  },
  {
    path: '/sc-purchaser/earnest-money/earnestMoneyPayView',
    name: 'earnestMoneyPayView',
    meta: {
      title: '保证金缴纳',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-purchaser/earnest-money/earnestMoneyPayView',
  },
  {
    path: '/sc-purchaser/bid-purchase-list/index',
    name: 'BidPurchaseLis',
    meta: {
      title: '标书购买清单记录',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-purchaser/bid-purchase-list/index',
  },
  {
    path: '/sc-supplier/bid/bid_company_detail',
    name: 'BidCompanyDetail',
    meta: {
      title: '中标详情',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-supplier/bid/bid_company_detail',
  },
  {
    path: '/sc-purchaser/bid-purchase-list/detail',
    name: 'BidPurchaseDetail',
    meta: {
      title: '购买标书详情',
      roles: ["admin", "super", "some"],
    },
    component: 'sc-purchaser/bid-purchase-list/detail',
  },
  {
    path: "/supplier/changePriceRecord/index",
    name: "changePriceRecordIndex",
    meta: {
      title: "订单修改记录",
      roles: ["admin", "super", "some"],
    },
    component: "supplier/changePriceRecord/index",
  },
]
